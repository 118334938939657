'use strict'

import ScrollMagic from 'scrollmagic';

const self = {
  container : document.getElementsByTagName('main'),
  controller : new ScrollMagic.Controller(),

  init : () => {
    self.setupElements();
    self.registerEvents();
  },

  setupElements : () => {

  },

  registerEvents : () => {

    const bubbles = [...document.querySelectorAll('.bubble')];
    bubbles.forEach((bubble) => {

      const scene = new ScrollMagic.Scene({
        triggerElement: bubble,
        triggerHook: 0.7,
        offset: 0
      });

      scene.setClassToggle(bubble, "animate")
      // .addIndicators()
      .addTo(self.controller);
    });
    
  },

}

export default self
