'use strict'

const self = {
  container : document.querySelector('.unsubscribe'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
  },

  registerEvents : () => {
    const triggerButton = document.querySelector('.unsubscribe-trigger');
    const closeButton = document.querySelector('.unsubscribe__closebtn');
    
    triggerButton.addEventListener('click', e => {
      self.container.style.opacity= 1;
      self.container.style.visibility = 'visible';
    });

    closeButton.addEventListener('click', e => {
      self.container.style.opacity= 0;
      self.container.style.visibility = 'hidden';
    })
  }

}

export default self
