'use strict'

const self = {
  container : document.querySelector('.page'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
  },

  registerEvents : () => {
    // Official documentation is here: https://developers.livechatinc.com/docs/extending-ui/extending-chat-widget/javascript-api/
    
    // Open chat window when click the button
    const triggers = [...document.querySelectorAll('.letschat-trigger')];
    triggers.forEach((trigger, index) => {
      trigger.addEventListener('click', e => {
        LC_API.open_chat_window();
      });
    });

  }
}

export default self