import bodymovin from 'lottie-web';

export default class CustomBodymovin {
  constructor(opts = {}) {
    this.elm = opts.elm || document.createElement('div');
    this.width = opts.width || window.innerWidth;
    this.height = opts.height || window.innerHeight;
    this.data = opts.data || null;
    this.loop = opts.loop || false;

    this.init();
    this.initListener(opts);
  }

  init() {
    this.anim = bodymovin.loadAnimation({
      container: this.elm,
      renderer: "svg",
      loop: this.loop,
      autoplay: false,
      autoloadsegments: true,
      rendererSettings: {
        progressiveLoad: false
      },
      animationData: this.data
    });
  }

  initListener(opts) {
    if (opts.onComplete) this.anim.addEventListener('complete', opts.onComplete)
  }

  play() {
    this.anim.play();
  }
  stop() {
    this.anim.stop();
  }
  pause() {
    this.anim.pause();
  }
  destroy() {
    this.anim.destroy();
  }
  setSpeed(int) {
    this.anim.setSpeed(int);
  }
  goToAndStop(int, isFrame = false) {
    this.anim.goToAndStop(int, isFrame);
  }
  totalFrames() {
    return this.anim.totalFrames;
  }

  _onResize() {
    const ww = window.innerWidth * 2;
    const wh = window.innerHeight * 2;
    const ratio = ww / this.width;
    const scaleY = wh / (this.height * ratio);
    const svg = this.elm.querySelector('svg');

    svg.style.transform = `translate3d(0,0,0) scale3d(1, ${scaleY}, 1)`;
  }

}