'use strict'

import { TweenMax } from "gsap";
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

const self = {
  container : document.querySelector('.numbers__charts'),
  controller : new ScrollMagic.Controller(),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {

  },

  registerEvents : () => {
  
    const segments = [...document.querySelectorAll('.donut-segment')];
    segments.forEach((segment) => {

      const scene = new ScrollMagic.Scene({
        triggerElement: segment,
        triggerHook: 0.8,
      });

      scene.setClassToggle(segment, "is-active")
      .addTo(self.controller)

      // The value of each pie chart is on page-about.scss
    });
  }

}

export default self
