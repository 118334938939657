'use strict'
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

const self = {
  container : document.getElementsByTagName('header'),
  hamburger : document.querySelector('.header-mobile__hamburger'),
  nav : document.querySelector('.nav-mobile'),
  closebtn : document.querySelector('.nav-mobile__closebtn'),

  init : () => {
    self.setupElements()
    self.toggleHamburger()
    self.shrinkLogo()
  },

  setupElements : () => {
  },

  toggleHamburger : () => {
    self.hamburger.addEventListener('click', e => {
      self.nav.classList.toggle('is-active');
    });

    self.closebtn.addEventListener('click', e => {
      self.nav.classList.remove('is-active');
    });
  },

  shrinkLogo : () => {

    const headerMobile = document.querySelector('.header-mobile');
    const headerMobileLogo = document.querySelector('.header-mobile__logo');
    let startPosition = 0;

    window.onscroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const currentPosition = scrollTop;
      const headerHeight = headerMobile.clientHeight;

      if (currentPosition > 300) {
        headerMobileLogo.classList.add('shrink'); 
      } else {
        headerMobileLogo.classList.remove('shrink'); 
      }
    }
  },

}

export default self
