'use strict';

// Dependancies
import "babel-polyfill"

// Modules
import Header from "./modules/_header";
import BodymovinModule from './modules/_bodymovinModule';
import Bubble from "./modules/_bubble";
import PieChart from "./modules/_pieChart";
// import GetInTouch from "./modules/_getintouch";
// import PopUpForm from "./modules/_popupform";
import Unsubscribe from "./modules/_unsubscribe";
import OpenChatWindow from "./modules/_openChatWindow";
import AOS from 'aos';

// Global Functionality

AOS.init({
  once: true,
});


// Conditional Functionality
const m = [
  {
    o : Header,
    i : Header.container 
  },
  {
    o : BodymovinModule,
    i : BodymovinModule.container 
  },
  {
    o : Bubble,
    i : Bubble.container
  },
  {
    o : PieChart,
    i : PieChart.container
  },
  // {
  //   o : GetInTouch,
  //   i : GetInTouch.container
  // },
  // {
  //   o : PopUpForm,
  //   i : PopUpForm.container
  // },
  {
    o : Unsubscribe,
    i : Unsubscribe.container
  },
  {
    o : OpenChatWindow,
    i : OpenChatWindow.container
  },
  // { o : MODULE,                 i : MODULE.container },
].map( a => (a.i) ? a.o.init() : null )
