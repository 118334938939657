'use strict'

import $ from 'jquery';
import inview from 'jquery-inview';
import CustomBodymovin from './_bodymovin';
import logoAnimationData from '../json/logo.json';

import aboutBubbleData from '../json/aboutBubble.json';
import aboutChart37Data from '../json/aboutChart37.json';
import aboutChart71Data from '../json/aboutChart71.json';
import aboutChart66Data from '../json/aboutChart66.json';
import aboutChart29Data from '../json/aboutChart29.json';

const self = {
  container: document.querySelector('body'),

  logoAnimation: new CustomBodymovin({
    elm: document.getElementById('bodymovin-logo'),
    data: logoAnimationData
  }),

  aboutBubble: new CustomBodymovin({
    elm: document.getElementById('about-bubble'),
    data: aboutBubbleData
  }),

  aboutChart37: new CustomBodymovin({
    elm: document.getElementById('aboutchart-37'),
    data: aboutChart37Data
  }),

  aboutChart71: new CustomBodymovin({
    elm: document.getElementById('aboutchart-71'),
    data: aboutChart71Data
  }),

  aboutChart66: new CustomBodymovin({
    elm: document.getElementById('aboutchart-66'),
    data: aboutChart66Data
  }),

  aboutChart29: new CustomBodymovin({
    elm: document.getElementById('aboutchart-29'),
    data: aboutChart29Data
  }),


  init: () => {
    self.setupElements()
    self.registerEvents()
    self.animateWhenShow()
  },

  setupElements: () => {
    self.logoAnimation.stop();
    self.logoAnimation.play();
  },

  registerEvents: () => {

  },

  animateWhenShow: () => {

    $('#aboutchart-37').on('inview', function() {
      self.aboutChart37.stop();
      self.aboutChart37.play();
    });

    $('#aboutchart-71').on('inview', function() {
      self.aboutChart71.stop();
      self.aboutChart71.play();
    });

    $('#aboutchart-66').on('inview', function() {
      self.aboutChart66.stop();
      self.aboutChart66.play();
    });

    $('#aboutchart-29').on('inview', function() {
      self.aboutChart29.stop();
      self.aboutChart29.play();
    });
  }

}

export default self